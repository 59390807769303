import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import toast from "react-hot-toast";

import { endpoints } from "constants/apiEndpoints";
import { generalErrors } from "constants/errorMessages";
import { SentryLoggingService } from "init/SentryLoggingService";
import type { PaginatedResponse } from "types/Api/PaginatedResponse";
import type { RepaymentTransaction } from "types/repayment/RepaymentTransaction";

export const useGetRepaymentTransactions = () => {
  const getRepaymentTransactions = useQuery({
    queryKey: ["getRepaymentTransactions"],
    queryFn: async () => {
      try {
        const { data } = await axios.get<
          PaginatedResponse<RepaymentTransaction>
        >(endpoints.MANAGE.API.CREDIT_ACCOUNTS.REPAYMENTS_TRANSACTIONS);

        return data;
      } catch (error: any) {
        console.error("Error in getRepaymentTransactions:", error);
        toast.error(generalErrors.SOMETHING_WENT_WRONG, {
          duration: 3000,
        });
        SentryLoggingService.captureException(error.message, {
          feature: "[Wallets V2][Credit Card Detail]",
          file: "useGetRepaymentTransactions.tsx",
          function: "useGetRepaymentTransactions",
        });
        throw error;
      }
    },
  });

  return {
    getRepaymentTransactions,
  };
};
