import { get } from "lodash";
import { useContext, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import { Divider } from "components/Divider";
import { TableRecord } from "components/Table/TableRecord";
import Table from "components/Table/V2/TableV2";
import Pagination from "components/ui/Pagination/PaginationV2";
import { Button } from "design_system/Button";
import { RepaymentsIsland } from "./RepaymentsIsland";
import { RepaymentsSidesheetRoot } from "./Sidesheet/RepaymentsSidesheetRoot";
import { TableEmptyState } from "./TableEmptyState";

import { useScreenDimension } from "hooks/useScreenDimension";
import { useAppSelector } from "store";
import { useUpcomingRepaymentsTable } from "../../../hooks/tables/useUpcomingRepaymentsTable";

import { IslandContext } from "design_system/Island/IslandContext";
import { SideSheetContext } from "design_system/Sidesheet/SideSheetContext";

import { queryClient } from "config/reactQueryClient";
import { setSelectedRows } from "reducers/redux/TableReducer/tableReducer";
import type { PaginatedResponse } from "types/Api/PaginatedResponse";
import type { RepaymentTransaction } from "types/repayment/RepaymentTransaction";
import { getRemainingDaysMapping } from "../../../utils/repaymentUtils";

export const RepaymentsTable = () => {
  /* ---------------------------------
   *  Context APIS & CACHES
   * ---------------------------------
   */
  const { openIsland, closeIsland } = useContext(IslandContext);
  const { isSideSheetOpen, openSideSheet } = useContext(SideSheetContext);
  const repayments: PaginatedResponse<RepaymentTransaction> =
    queryClient.getQueryData(["getRepaymentTransactions"]);

  const { selectedRows, recordDetail } = useAppSelector(
    (state) => state.table.repayments,
  );

  /* ---------------------------------
   *  Hooks
   * ---------------------------------
   */
  const dispatch = useDispatch();
  const { isMobile } = useScreenDimension();

  const { COLUMNS, handleSetPage, handlePaymentBtnClick } =
    useUpcomingRepaymentsTable();

  /* ---------------------------------
   *  Constants
   * ---------------------------------
   */

  const columns = useMemo(() => COLUMNS, [JSON.stringify(recordDetail)]);
  const tableData = useMemo(() => repayments.data, [repayments.data]);
  const isEmptyTable = get(repayments, "data", []).length === 0;

  const paginationMeta = {
    ...repayments.paginationMeta,
    itemName: "Repayments",
  };

  /* ---------------------------------
   *  Helpers
   * ---------------------------------
   */
  const handleRowSelections = (params) => {
    dispatch(
      setSelectedRows({
        tableName: "repayments",
        selectedRows: params,
      }),
    );
  };

  /* ---------------------------------
   *  Effects
   * ---------------------------------
   */

  // OPEN/CLOSE ISLAND ON ROW SELECTIONS
  useEffect(() => {
    if (selectedRows?.length > 0) {
      openIsland();
    } else closeIsland();
  }, [selectedRows.length]);

  // Laptop and Tablet View
  if (!isMobile) {
    return (
      <div className="mt-24 flex w-full justify-center px-40">
        <Table
          name="upcoming-repayments-table"
          columns={columns}
          data={tableData}
          paginationMeta={paginationMeta}
          onSetPage={handleSetPage}
          tableClass="table"
          headerRowClass="bg-black-25"
          headerCellClass="w-auto"
          rowProps={{
            selectable: true,
            selectedRows,
            setSelectedRows: handleRowSelections,
          }}
          emptyState={<TableEmptyState />}
        />
        {isSideSheetOpen && <RepaymentsSidesheetRoot />}
        <RepaymentsIsland />
      </div>
    );
  }

  return isEmptyTable ? (
    <TableEmptyState />
  ) : (
    <div className="mt-16">
      {repayments.data?.map((repayment: any, index: number) => {
        const cardRepaymentRemainingDays = repayment.cardRepaymentRemainingDays;
        const remainingDays = getRemainingDaysMapping(
          cardRepaymentRemainingDays,
        );

        return (
          <TableRecord key={repayment.identifier + index}>
            <TableRecord.Tag
              tagProps={{
                size: "xl",
                iconOnly: true,
                Icon: remainingDays.Icon,
                color: remainingDays.color,
              }}
            />
            <TableRecord.Column1
              label={repayment.billingAmountFormatted}
              supportingText={`${repayment.cardRepaymentDate} - ${remainingDays.label}`}
            />
            <TableRecord.Column2
              label={
                <Button
                  label="Pay"
                  variant="filled"
                  size="sm"
                  onClick={() => handlePaymentBtnClick(repayment)}
                />
              }
              className="inline-flex justify-end"
            />
          </TableRecord>
        );
      })}
      <Divider />
      <div className="disbursements__pagination px-16">
        <Pagination
          itemName={paginationMeta.itemName}
          meta={paginationMeta}
          onSetPage={handleSetPage}
        />
      </div>
      {isSideSheetOpen && <RepaymentsSidesheetRoot />}
    </div>
  );
};
